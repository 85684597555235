<template>
  <div class="mt-3">
    <div class="header-content">
      <h3>
        Guia {{ type }}
        <span v-if="type != 'de outras despesas'">
          - Nº {{ tissGuide.provider_guide }}
        </span>
      </h3>
      <v-tiss-guide-situation :situation="tissGuide.situation" />
      <div class="div-button">
        <b-button
          variant="link"
          @click="openDocumentListModal"
          v-if="tissGuide.files_count > 0"
        >
          <Clip class="icon" /> Abrir arquivos
        </b-button>

        <b-button variant="link" @click="openDocumentModal" v-else>
          <span><Clip class="icon" /> Anexar arquivo</span>
        </b-button>
      </div>
    </div>
    <div class="card" @click="openModalUpdateTiss">
      <div class="doctor" v-if="professional">
        <span>
          {{ professional.name }}
          <span v-if="professional.crms && professional.crms.length">
            ({{ professional.crms[0].number }})
          </span>
        </span>
      </div>
      <div class="procedure">
        <span v-if="tissGuide.items && tissGuide.items[0]">
          {{ tissGuide.items[0].item?.code }} -
          {{ tissGuide.items[0].item ? tissGuide.items[0].item.name : tissGuide.items[0].description }}
        </span>

        <span
          v-if="
            tissGuide.materials_procedures && tissGuide.materials_procedures[0]
          "
        >
          {{ tissGuide.materials_procedures[0].item.code }} -
          {{ tissGuide.materials_procedures[0].item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Clip from '@/assets/icons/clip.svg'
import TissGuideSituation from './TissGuideSituation'

export default {
  name: 'TissGuideCard',
  props: {
    tissGuide: Object
  },
  components: {
    Clip,
    'v-tiss-guide-situation': TissGuideSituation
  },
  data() {
    return {
      type: '',
      handleGuideTypeEdit: ''
    }
  },
  computed: {
    professional() {
      if (this.tissGuide && this.tissGuide.guide.professional) {
        return this.tissGuide.guide.professional
      } else if (this.tissGuide && this.tissGuide.items.length) {
        return this.tissGuide.items[0].professionals[0]?.professional
      }
      return {}
    }
  },
  mounted() {
    this.getTypes()
  },
  methods: {
    getTypes() {
      const type = this.tissGuide.guide_type.split('\\').at(-1)
      const types = {
        TissGuideConsultation: 'de consulta',
        TissGuideSpSadt: 'SP/SADT',
        TissGuideHospitalizationRequest: 'de solicitação de internação',
        TissGuideFee: 'de honorário',
        TissGuideHospitalizationSummary: 'de resumo de internação',
        TissGuideOtherExpense: 'de outras despesas'
      }
      this.type = types[type]

      const typesEdit = {
        TissGuideConsultation: 'consultation_guide',
        TissGuideSpSadt: 'sp_sadt_guide',
        TissGuideHospitalizationRequest: 'hospitalization_request_guide',
        TissGuideFee: 'fee_guide',
        TissGuideHospitalizationSummary: 'hospitalization_summary_guide',
        TissGuideOtherExpense: 'other_expenses_guide'
      }
      this.handleGuideTypeEdit = typesEdit[type]
    },
    openDocumentModal() {
      this.$emit('open-document-modal', this.tissGuide)
    },
    openDocumentListModal() {
      this.$emit('open-document-list-modal', this.tissGuide)
    },
    openModalUpdateTiss(event) {
      event.preventDefault()
      this.$emit('open-modal-update-tiss', {
        tissGuide: this.tissGuide,
        type: this.handleGuideTypeEdit
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}
.header-content {
  display: flex;
  flex-direction: row;

  .div-button {
    margin-left: auto;
    font-style: normal;
    button {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }
  }
}
.card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;

  .doctor {
    margin-top: 1%;
    margin-left: 2%;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }

  .procedure {
    margin-top: 1%;
    margin-left: 2%;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 1%;
  }
  background-color: var(--neutral-100);
  &:hover {
    background-color: var(--neutral-200);
  }
}
</style>
