<template>
    <div v-if="clinicHealthPlan && clinicHealthPlan.invoicing_tiss_active">
        <b-row>
            <b-col cols="12">
              <hr />
            </b-col>

            <b-col cols="12">
              <p class="tiss-title">Espaço TISS</p>
            </b-col>

            <b-col cols="12">
              <multiselect
                placeholder="Adicionar nova Guia TISS"
                v-model="guide_type"
                class="guides"
                ref="multiselect"
                :options="guideOptions"
                @input="handleGuide"
                :showLabels="false"
                :internalSearch="true"
                :searchable="true"
                :close-on-select="true"
              >
                <template slot="caret">
                  <div class="chevron">
                    <v-chevron-down />
                  </div>
                </template>

                  <template slot="option" slot-scope="props">
                    <p v-if="props.option == 'consultation_guide'">Guia de consulta</p>
                    <p v-else-if="props.option == 'sp_sadt_guide'">Guia SP/SADT</p>
                    <p v-else-if="props.option == 'hospitalization_request_guide'">Guia de solicitação de internação</p>
                    <p v-else-if="props.option == 'hospitalization_summary_guide'">Guia de resumo de internação</p>
                    <p v-else-if="props.option == 'fee_guide'">Guia de honorários</p>
                    <p v-else-if="props.option == 'other_expenses_guide'">Guia de despesas</p>
                  </template>

                  <template slot="singleLabel" slot-scope="{ option }">
                    <p v-if="option == 'consultation_guide'">Guia de consulta</p>
                    <p v-else-if="option == 'sp_sadt_guide'">Guia SP/SADT</p>
                    <p v-else-if="option == 'hospitalization_request_guide'">Guia de solicitação de internação</p>
                    <p v-else-if="option == 'hospitalization_summary_guide'">Guia de resumo de internação</p>
                    <p v-else-if="option == 'fee_guide'">Guia de honorários</p>
                    <p v-else-if="option == 'other_expenses_guide'">Guia de despesas</p>
                  </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                </multiselect>
            </b-col>

          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            ref="scroll"
          >
            <b-col cols="12" class="list">
              <div v-for="tissGuideCard in tissGuidesComputed" :key="tissGuideCard.index" >
                <v-tiss-guide-card
                  :tissGuide="tissGuideCard"
                  @open-document-modal="openDocumentModal"
                  @open-document-list-modal="openDocumentListModal"
                  @open-modal-update-tiss="openModalUpdateTiss"
                />
              </div>
            </b-col>
          </perfect-scrollbar>

          <b-col cols="12" class="mt-3">
            <hr />
          </b-col>
        </b-row>

        <v-tiss-guide-modals-container
          :patient="patient"
          :tissGuides="tissGuidesComputed"
          :tissGuide="tissGuide"
          :clinicHealthPlan="clinicHealthPlan"
          :appointmentId="appointmentId"
          :required_fields="clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field"
          :getLastTissGuide="getLastTissGuide"
          :clearTissGuide="clearTissGuide"
        />

        <v-tiss-guide-file-container
          :file="file"
          :tissGuide="tissGuide"
          :openDocumentModal="openDocumentModal"
          :openEditFileModal="openEditFileModal"
          :clearFile="clearFile"
          :files="files"
        />
    </div>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import TissGuideCard from './TissGuideCard.vue'
import TissGuidesModalsContainer from '@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue'
import TissGuideFilesContainer from '@/layouts/InvoicingTiss/TissGuide/TissGuideFilesContainer.vue'
import { handleGuideSelected } from '@/utils/invoicingTissHelper'

export default {
  name:'TissModule',
  components: {
    'v-chevron-down': ChevronDown,
    'v-tiss-guide-card': TissGuideCard,
    'v-tiss-guide-modals-container': TissGuidesModalsContainer,
    'v-tiss-guide-file-container': TissGuideFilesContainer
  },
  props: {
    patient: Object,
    patient_health_plan_id: String,
    appointmentId: String
  },
  async mounted() {
    await this.getTissGuidesByAppointmentId()
    await this.getClinicHealthPlan()
  },
  data () {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      clinicHealthPlan: {},
      guide_type: null,
      guideOptions: [],
      tissGuides: [],
      tissGuide: {},
      file: {},
      files: []
    }
  },
  computed: {
    tissGuidesComputed() {
      return this.tissGuides
    }
  },
  methods: {
    async getClinicHealthPlan(){
      if (!this.clinic.id || !this.patient_health_plan_id) return
      try {
        const response = await this.api.getClinicHealthPlanByHealthId(this.clinic.id, this.patient_health_plan_id)
        this.clinicHealthPlan = response.data

        if (this.clinicHealthPlan && this.clinicHealthPlan.invoicing_tiss_setting){
          this.setGuideTypes()
        }
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    setGuideTypes() {
      this.guideOptions = []
      const guideOptions = [
        'consultation_guide',
        'sp_sadt_guide',
        'hospitalization_request_guide',
        'hospitalization_summary_guide',
        'fee_guide',
        'other_expenses_guide'
      ]
      const types = this.clinicHealthPlan.invoicing_tiss_setting.tiss_using_guide_type
      guideOptions.forEach(type => {
        if (types[type]) {
          this.guideOptions.push(type)
        }
      })
    },
    async getGuide(id) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideById(id)
        this.tissGuide = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    handleGuide() {
      const modal = handleGuideSelected(this.guide_type)
      this.$bvModal.show(modal)
      this.guide_type = null
    },
    async getTissGuidesByAppointmentId() {
      if ( !this.appointmentId) return;
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuidesByAppointment(this.appointmentId)
        this.tissGuides = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getLastTissGuide(data) {
      const loading = this.$loading.show()
      try {
        const response = await this.api.findTissGuideByGuideId(data.id)
        await this.getClinicHealthPlan(response.data.invoicing_tiss_setting_id)
        this.updateArrayList(response.data)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    updateArrayList(guide) {
      const index = this.tissGuides.findIndex(tissGuide => tissGuide.guide.id === guide.guide.id)
      if (index > -1) {
        this.$set(this.tissGuides, index, guide)
      } else {
        this.$set(this.tissGuides, this.tissGuides.length, guide)
      }
    },
    openDocumentModal(tissGuide) {
      this.tissGuide = tissGuide
      this.$bvModal.show('tiss-guide-file-modal')
    },
    async getTissGuideFiles(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideFiles(tissGuideId)
        return response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openDocumentListModal(tissGuide) {
      this.tissGuide = tissGuide
      this.files = await this.getTissGuideFiles(tissGuide.id)
      this.$bvModal.show('tiss-guide-file-list-modal')
    },
    openEditFileModal(file) {
      this.file = file
      this.$bvModal.show('tiss-guide-file-modal')
    },
    async openModalUpdateTiss({ tissGuide, type }) {
      this.guide_type = type
      await this.getGuide(tissGuide.id)
      this.handleGuide()
    },
    clearFile() {
      this.file = {}
    },
    clearTissGuide() {
      this.tissGuide = {}
    }
  },
  watch: {
    'patient_health_plan_id': async function () {
      await this.getClinicHealthPlan()
    }
  }
}
</script>

<style lang="scss" scoped>
  .tiss-title{
    color:var(--neutral-600);
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 28px;
  }

  .ps {
    max-height: 500px;
    margin-top: 25px;
    width: 100%;
  }

.flex-fill {
    display: flex;

    &.body-schedule {
        margin-bottom: 20px;

        &.hasSnap {
            margin-bottom: 10px !important;
        }
    }
}

.guides {
    border: 1px solid var(--neutral-300);
    border-radius: 8px;

    .multiselect__tags {
        padding-left: 15px;
        min-height: 38px;

        .multiselect__placeholder {
            color: var(--type-placeholder);
            font-size: 16px;
            padding: 0px;
        }

        .multiselect__single {
            display: flex;
            flex-direction: row;
            gap: 7px;
            align-items: center;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--type-active);
        }
    }

    .plc {
        color: var(--type-placeholder);
        font-size: 16px;
        padding: 0px;
    }

    .multiselect__option {
        gap: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}



.values-wrapper {
  width: 100%;
  margin: 10px 0;
  display: inline-flex;
  align-items: center;
  justify-content: start;
}

.values-input {
  width: 100%;
}

.currency-type {
  max-width: 80px !important;
  margin-bottom: 0px;
}

.space {
  width: 20px;
}

</style>
