<template>
  <div class="div-status">
    <div class="status-color" :class="getSituation"></div>
    {{ situation }}
  </div>
</template>

<script>
import { TissGuide } from '@/modules/tiss/manageGuides/utils/statuses'
export default {
  name: 'TissGuideSituation',
  props: {
    situation: {
      type: String,
      required: true
    }
  },
  computed: {
    getSituation() {
      const situations = {
        [TissGuide.GUIDE_SITUATION_IN_PREPARATION]: 'IN_PREPARATION',
        [TissGuide.GUIDE_SITUATION_WAITING_AUTHORIZATION]: 'WAITING_AUTHORIZATION',
        [TissGuide.GUIDE_SITUATION_AUTHORIZED]: 'AUTHORIZED',
        [TissGuide.GUIDE_SITUATION_DENIED]: 'DENIED',
        [TissGuide.GUIDE_SITUATION_CANCELED]: 'CANCELED',
        [TissGuide.GUIDE_SITUATION_PAYED]: 'PAID',
        [TissGuide.GUIDE_SITUATION_GLOSSED]: 'GLOSSED',
        default: 'UNINFORMED'
      }
      return situations[this.situation] || situations.default
    }
  }
}
</script>

<style lang="scss" scoped>
.div-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1%;
  margin-left: 1%;
  justify-content: space-between;
  .status-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 100%;
    
    &.UNINFORMED {
      background-color: var(--neutral-300);
    }

    &.default {
      background-color: var(--neutral-300);
    }

    &.IN_PREPARATION {
      background-color: var(--yellow);
    }

    &.WAITING_AUTHORIZATION {
      background-color: var(--light-orange-500);
    }

    &.AUTHORIZED {
      background-color: var(--states-success);
    }

    &.DENIED {
      background-color: var(--states-red-soft);
    }

    &.FINISHED {
      background-color: var(--blue-500);
    }

    &.CANCELED {
      background-color: var(--type-active);
    }

    &.PAID {
      background-color: var(--light-blue);
    }

    &.GLOSSED {
      background-color: var(--states-error);
    }

    &.ON_APPEAL {
      background-color: #6100ff;
    }

    &.RECOVERED {
      background-color: var(--states-success-dark-green);
    }
  }
}
</style>
